.App {
  text-align: center;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  position: fixed;
  background-image: linear-gradient(180deg, #fff3df, #ffd68f);
  padding: 0px 0px 25px 0px;
  height: 20px;
}

.header-logo {
  height: 30px;
  pointer-events: none;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding: 8px 0px 0px 8px;
}

a.header:hover {
  transform: translateY(-2px);
}

.App-body {
  background-color: #fff3df;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: #232039;
}

.App-link {
  color: #289f8a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.field {
  width: max(33%, 350px);
  height: 56px;
  border-radius: 4px;
  position: relative;
  margin: 15px 0px 15px 0px;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px #232039;
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px #232039;
}

.field.active input {
  padding: 12px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #232039;
}

.field input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: #232039;
}
.field input::-moz-placeholder {
  color: #232039;
}
.field input:-ms-input-placeholder {
  color: #232039;
}
.field input:-moz-placeholder {
  color: #232039;
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #232039;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.results {
  color: #232039;
}

.footer {
  color: #282c34;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(180deg, #ffd68f, #fff3df);
  padding: 0px 0px 25px 0px;
  height: 20px;
}

.copyright {
  color:rgb(95, 93, 93);
  font-size: 9.5pt;
  padding-left:10px;
  padding-top:15px;
  text-align: left;
}

a.copyright{
  color: #289f8a;
}

.social {
  color:rgb(95, 93, 93);
  align-content: center;
}

.github {
  color: rgb(95, 93, 93);
  float: right;
  padding: 10px 15px 0px 0px;
}

.github:hover {
  transform: translateY(-2px);
}